body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.product-img {
  margin-top: 25%;
}

@media screen and (min-width: 600px) {
  .add-btn {
    position: fixed;
    top: 70vh;
    right: 25vw;
    z-index: 2;
  }

  .product-img {
    max-width: 145px;
    height: max-content;
  }
}

@media screen and (max-width: 600px) {
  .add-btn {
    position: fixed;
    top: 70vh;
    right: 5vw;
    z-index: 2;
  }

  .product-img {
    max-width: 75px;
    height: max-content;
  }
}

@media screen and (max-width: 400px) {
  .product-img {
    max-width: 50px;
    height: max-content;
  }
}

@media screen and (max-width: 280px) {
  .product-img {
    display: none;
  }
}